// src/store/redux/slices/configSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Config } from '../../../types/interfaces'
import { AppState } from '../types'
import { WritableDraft } from 'immer'
import { ConfigAPI } from '../../../api/ConfigAPI'
import { ConfigGlobal, RegionApiCodes } from '../../../types/types'
import { defaultConfig } from '../../../constants/defaults'
import { loadConfig, LocalConfig } from '../../../utils/loadConfig'
import { PURGE } from 'redux-persist/es/constants'

export interface ConfigState {
  config: ConfigGlobal
  localConfig: LocalConfig
  isLoading: boolean
  error: string | null
}

export const initialState: ConfigState = {
  config: defaultConfig,
  localConfig: {},
  isLoading: false,
  error: null,
}

export const fetchLocalConfig = createAsyncThunk<LocalConfig, void>(
  'news/fetchLocalConfig',
  async (_, { rejectWithValue }) => {
    try {
      return await loadConfig()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchConfig = createAsyncThunk<Config, RegionApiCodes>(
  'news/fetchConfig',
  async (regionCode, { getState, rejectWithValue }) => {
    const configState = (getState() as AppState).config as ConfigState
    if (configState.config[regionCode]) {
      return configState.config[regionCode]
    }
    const configAPI: ConfigAPI = new ConfigAPI(regionCode)
    try {
      return await configAPI.getConfig()
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchLocalConfig.pending, (state: WritableDraft<ConfigState>) => {
        state.isLoading = true
      })
      .addCase(fetchLocalConfig.fulfilled, (state: WritableDraft<ConfigState>, action) => {
        state.localConfig = action.payload
        state.isLoading = false
      })
      .addCase(fetchLocalConfig.rejected, (state: WritableDraft<ConfigState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
      .addCase(fetchConfig.pending, (state: WritableDraft<ConfigState>) => {
        state.isLoading = true
      })
      .addCase(fetchConfig.fulfilled, (state: WritableDraft<ConfigState>, action) => {
        state.config[action.meta.arg] = action.payload
        state.isLoading = false
      })
      .addCase(fetchConfig.rejected, (state: WritableDraft<ConfigState>, action) => {
        state.error = (action.error.message as string) || null
        state.isLoading = false
      })
  },
})

export default configSlice.reducer
