// src/store/redux/slices/regionSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'
import { Region } from '../../../types/interfaces'
import { AppState } from '../types'
import { LanguageCode, Languages, RegionApiCodes } from '../../../types/types'
import { PURGE } from 'redux-persist/es/constants'
import { ConfigState, fetchConfig } from './configSlice'
import { getLanguagesLocal } from '../../../utils/getLanguagesLocal'

export interface RegionState {
  name: string
  apiCode: RegionApiCodes
  code: string
  timeZone: string
  isLoading: boolean
  languages: Languages | null
  selectedLanguage: LanguageCode | null
  error: string
}

export const initialState: RegionState = {
  name: '',
  apiCode: 'sandbox',
  code: '',
  timeZone: '',
  isLoading: false,
  languages: null,
  selectedLanguage: null,
  error: '',
}

export const fetchLanguages = createAsyncThunk<Languages | null, RegionApiCodes>(
  'region/fetchLanguages',
  async (regionCode, { dispatch, getState, rejectWithValue }) => {
    try {
      await dispatch(fetchConfig(regionCode))
      const config = (getState() as AppState).config as ConfigState
      return config.config[regionCode]?.languageLabels || null
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    fetchLanguagesLocal(state: WritableDraft<RegionState>, action: PayloadAction<RegionApiCodes>) {
      state.languages = getLanguagesLocal(action.payload)
    },
    setRegion(state: WritableDraft<RegionState>, action: PayloadAction<Region>) {
      state.apiCode = action.payload.apiCode
      state.code = action.payload.code
      state.name = action.payload.name
      state.timeZone = action.payload.timeZone
    },
    setLanguage(state: WritableDraft<RegionState>, action: PayloadAction<LanguageCode | null>) {
      state.selectedLanguage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(fetchLanguages.pending, (state: WritableDraft<RegionState>) => {
        state.isLoading = true
      })
      .addCase(
        fetchLanguages.fulfilled,
        (state: WritableDraft<RegionState>, action: PayloadAction<Languages | null>) => {
          state.languages = action.payload
          state.isLoading = false
        },
      )
      .addCase(fetchLanguages.rejected, (state: WritableDraft<RegionState>, action) => {
        state.error = (action.error.message as string) || ''
        state.isLoading = false
      })
  },
})

export const { fetchLanguagesLocal, setRegion, setLanguage } = regionSlice.actions
export default regionSlice.reducer
