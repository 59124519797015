import React, { useState } from 'react'
import {
  IonButton,
  IonRippleEffect,
  IonAlert,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonSpinner,
} from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppState } from '../../../store/redux/types'
import { AuthState, setSignUpData, signUp } from '../../../store/redux/slices/authSlice'
import { getAgreementLabels } from '../../../constants/defaults'
import { useAppDispatch } from '../../../hooks/reduxHooks'
import { saveCredentialsToKeychain } from '../../../utils/saveCredentialsToKeychain'

interface ConfirmationProps {
  prevStep: () => void
}

const Confirmation: React.FC<ConfirmationProps> = ({ prevStep }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { signUpData } = useSelector((state: AppState): AuthState => state.auth)
  const [showAlert, setShowAlert] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const { firstname, lastname, email, password } = signUpData!
    const resultAction = await dispatch(signUp({ firstname, lastname, email, password }))
    if (signUp.fulfilled.match(resultAction)) {
      setShowAlert(true)
      await saveCredentialsToKeychain(email, password)
      dispatch(setSignUpData({ firstname: '', lastname: '', email: '', password: '' }))
    }
    setIsSubmitting(false)
  }
  const agreementLabels = getAgreementLabels(t)

  const handleAlertDismiss = () => {
    setShowAlert(false)
    history.replace('/login?slide=4')
  }

  return (
    <>
      <h1 className="ion-padding-vertical">{`${t('signup.confirmData')} - ${t('signup.step3')}`}</h1>
      <IonCard>
        <IonCardContent>
          <IonList lines="none">
            <IonItem>
              <IonLabel>
                {t('forms.firstName')}: {signUpData!.firstname}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t('forms.lastName')}: {signUpData!.lastname}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t('forms.emailAddress')}: {signUpData!.email}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={checkmarkCircle} className="ion-margin-end" color="primary" aria-hidden slot="start" />
              <IonLabel>{t('agreement.accept', { agreement: agreementLabels['gdpr'].accept })}</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={checkmarkCircle} className="ion-margin-end" color="primary" aria-hidden slot="start" />
              <IonLabel>{t('agreement.accept', { agreement: agreementLabels['eula'].accept })}</IonLabel>
            </IonItem>
            <IonItem>
              <IonIcon icon={checkmarkCircle} className="ion-margin-end" color="primary" aria-hidden slot="start" />
              <IonLabel>{t('agreement.accept', { agreement: agreementLabels['volunteer'].accept })}</IonLabel>
            </IonItem>
          </IonList>
        </IonCardContent>
      </IonCard>
      <IonList lines="none">
        <IonItem>
          <IonLabel>
            <p className="ion-text-center ion-padding-vertical">{t('signup.submitText')}</p>
          </IonLabel>
        </IonItem>
        <IonItem className="ion-margin-top">
          <IonButton size="default" onClick={prevStep} fill="outline">
            {t('btn.previousStep')}
          </IonButton>
          {!isSubmitting ? (
            <IonButton size="default" onClick={handleSubmit} className="ion-activatable ripple-parent">
              {t('btn.signUp')}
              <IonRippleEffect />
            </IonButton>
          ) : (
            <IonButton size="default" disabled>
              {t('loading.sending')}
              <IonSpinner color="light" className="ion-margin-start" />
            </IonButton>
          )}
        </IonItem>
      </IonList>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={handleAlertDismiss}
        header={t('signup.signupSuccess')}
        message={t('login.codeSent')}
        buttons={[
          {
            text: t('btn.ok'),
            role: 'confirm',
            handler: handleAlertDismiss,
          },
        ]}
      />
    </>
  )
}

export default Confirmation
