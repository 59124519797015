// src/hooks/useAgreementContent.ts

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'
import { RegionState } from '../store/redux/slices/regionSlice'
import { AgreementAPI } from '../api/AgreementAPI'
import { Agreement } from '../types/interfaces'
import { LanguageCode, AgreementType } from '../types/types'

interface UseAgreementContentOptions {
  agreementType: AgreementType
  maxRetries?: number
}

interface UseAgreementContentReturn {
  agreementContent: string
  isLoading: boolean
  error: boolean
  loadedInDefaultLanguage: boolean
  retries: number
  getAgreement: () => Promise<void>
}

export const useAgreementContent = ({
  agreementType,
  maxRetries = 3,
}: UseAgreementContentOptions): UseAgreementContentReturn => {
  const [agreementContent, setAgreementContent] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [loadedInDefaultLanguage, setLoadedInDefaultLanguage] = useState(false)
  const [retries, setRetries] = useState(0)

  const { apiCode, languages, selectedLanguage } = useSelector((state: AppState): RegionState => state.region)

  useEffect(() => {
    setRetries(0)
    setIsLoading(true)
    setError(false)
    setAgreementContent('')
    setLoadedInDefaultLanguage(false)
  }, [agreementType, apiCode, selectedLanguage])

  const getAgreement = async () => {
    if (retries >= maxRetries) {
      return
    }
    setIsLoading(true)
    setError(false)
    const agreementAPI = new AgreementAPI(apiCode, selectedLanguage)
    try {
      const agreement: Agreement = await agreementAPI.getAgreement(agreementType)
      setAgreementContent(agreement.content)
      setLoadedInDefaultLanguage(false)
    } catch (error) {
      console.error('Failed to load agreement in selected language:', error)
      try {
        // Attempt to load agreement in default language
        const langKeys = Object.keys(languages || {}) as LanguageCode[]
        const defaultLanguage = langKeys.length > 0 ? langKeys[0] : 'en-GB'
        // const defaultLanguage = 'nl-BE'
        const agreementAPIDefault = new AgreementAPI(apiCode, defaultLanguage)
        const agreementDefault = await agreementAPIDefault.getAgreement(agreementType)
        setAgreementContent(agreementDefault.content)
        setLoadedInDefaultLanguage(true)
      } catch (error) {
        console.error('Failed to load agreement in default language:', error)
        setError(true)
        setAgreementContent('')
        setLoadedInDefaultLanguage(false)
      }
    } finally {
      setRetries((prevRetries) => prevRetries + 1)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (retries < maxRetries) {
      getAgreement().then((r) => r)
    }
  }, [agreementType, apiCode, selectedLanguage])

  return {
    agreementContent,
    isLoading,
    error,
    loadedInDefaultLanguage,
    retries,
    getAgreement,
  }
}
