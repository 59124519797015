// src/store/redux/middleware/errorHandlingMiddleware.ts

import { isRejected, UnknownAction, Middleware } from '@reduxjs/toolkit'
import { setError } from '../slices/errorSlice'
import i18n from 'i18next'
import { AxiosError } from 'axios'

interface RejectedActionWithPayload extends UnknownAction {
  payload?: AxiosError | string
  error?: {
    message?: string
  }
  meta?: {
    aborted?: boolean
  }
  type: string
}

export const errorHandlingMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejected(action)) {
      let displayError: boolean = true
      let errorTitle: string = 'errors.unableToLoad'
      let errorMessage: string = 'errors.checkLater'

      // Type guard to ensure that the action has payload and error properties
      const rejectedAction = action as RejectedActionWithPayload

      // Check for network errors
      if (
        rejectedAction.error?.message === 'Rejected' &&
        typeof rejectedAction.payload !== 'string' &&
        rejectedAction.payload?.message.includes('Network Error')
      ) {
        errorTitle = 'errors.connectionProblemTitle'
        errorMessage = 'errors.connectionProblemDesc'
      } else {
        if (rejectedAction.error?.message) {
          errorMessage = rejectedAction.error.message
        }

        // Customize the message based on the action type if needed
        switch (rejectedAction.type) {
          case 'auth/activateAccount/rejected':
          case 'auth/updateEmail/rejected':
          case 'auth/validateToken/rejected':
            displayError = false
            break
          case 'auth/initialise/rejected':
            if (typeof rejectedAction.payload === 'string' && rejectedAction.payload === 'Not authenticated') {
              displayError = false
            }
            errorMessage = 'profilePage.profileError'
            break
          case 'auth/updateAgreementDate/rejected':
            errorTitle = 'errors.unableToUpdate'
            errorMessage = 'agreement.updateError'
            break
          case 'application/fetchApplications/rejected':
          case 'application/fetchProjectApplications/rejected':
            errorTitle = 'errors.unableToContact'
            errorMessage = 'volunteerPage.contactError'
            break
          case 'application/updateApplication/rejected':
          case 'application/updateApplicationAction/rejected':
            errorMessage = 'projectLeader.updateErr'
            break
          case 'news/fetchNews/rejected':
          case 'news/fetchNewsById/rejected':
            errorMessage = 'homePage.newsErrorMessage'
            break
          case 'profile/fetchProfile/rejected':
          case 'profile/fetchProfileOptions/rejected':
            errorMessage = 'profilePage.profileError'
            break
          case 'profile/updateProfile/rejected':
            errorMessage = 'profilePage.profileUpdateError'
            break
          case 'profile/deleteProfile/rejected':
            errorTitle = 'errors.unableToDelete'
            errorMessage = 'profilePage.profileDeletionError'
            break
          case 'projects/applyToProject/rejected':
            errorTitle = 'errors.unableToContact'
            errorMessage = 'volunteerPage.contactError'
            break
          case 'projects/fetchProjects/rejected':
            errorMessage = 'volunteerPage.projectsErrorMessage'
            break
          case 'projects/fetchProject/rejected':
            errorMessage = 'volunteerPage.projectErrorMessage'
            break
          case 'projects/fetchProjectsPL/rejected':
            errorMessage = 'more.clProjectsErr'
            break
          case 'projects/fetchShiftsPL/rejected':
            errorMessage = 'more.clProjectShiftsErr'
            break
          case 'projects/fetchProjectOptions/rejected':
            errorMessage = 'homePage.optionsErrorMessage'
            break
          case 'region/fetchLanguages/rejected':
            errorMessage = 'login.loadLanguagesErrorMessage'
            break
          case 'shifts/fetchShifts/rejected':
            errorMessage = 'homePage.shiftsErrorMessage'
            break
          case 'shifts/fetchShiftsProjectLeaders/rejected':
            errorMessage = 'shift.unableToLoadPLs'
            break
          case 'shifts/registerToShift/rejected':
            errorTitle = 'shift.registrationErrorTitle'
            errorMessage = 'shift.registrationErrorDescDefault'
            if (typeof rejectedAction.payload === 'string' && rejectedAction.payload === 'Blocked') {
              errorMessage = 'shift.registrationErrorDescBlocked'
            }
            break
          case 'shifts/updateVolunteerShiftHourStatus/rejected':
            errorMessage = 'more.updateShiftPresenceErr'
            break
          case 'shifts/updateVolunteerShiftAnnouncement/rejected':
            errorMessage = 'more.updateAnnouncementErr'
            break
        }
        if (!displayError) {
          return next(action)
        }

        // Custom error messages based on status codes or error content
        if (rejectedAction.payload && typeof rejectedAction.payload !== 'string') {
          switch (rejectedAction.type) {
            case 'auth/login/rejected':
              errorTitle = 'login.thereWasAnError'
              errorMessage = 'login.otherIssueError'
              switch (rejectedAction.payload.response?.status) {
                case 401:
                  errorMessage = 'login.accountNotActivated'
                  break
                case 404:
                  errorMessage = 'login.userNotFound'
                  break
              }
              break

            case 'auth/signUp/rejected':
              errorTitle = 'login.thereWasAnError'
              errorMessage = 'login.otherIssueError'
              switch (rejectedAction.payload.response?.status) {
                case 401:
                  errorMessage = 'signup.signupAlreadyActiveErr'
                  break
              }
              break

            case 'auth/resetPassword/rejected':
              errorTitle = 'login.thereWasAnError'
              errorMessage = 'login.otherIssueError'
              switch (rejectedAction.payload.response?.status) {
                case 404:
                  errorMessage = 'login.resetWrongEmailErr'
                  break
              }
              break

            case 'auth/updatePassword/rejected':
              errorTitle = 'updatePassword.thereWasAnError'
              errorMessage = 'updatePassword.error'
              switch (rejectedAction.payload.response?.status) {
                case 400:
                  errorMessage = 'updatePassword.malformed'
                  break
                case 404:
                  errorMessage = 'updatePassword.notFound'
                  break
              }
              break

            case 'profile/updateProfile/rejected':
              errorTitle = 'errors.unableToUpdate'
              switch (rejectedAction.payload.response?.status) {
                case 401:
                  errorMessage = 'profilePage.profileEmailError'
                  break
              }
              break

            case 'projects/fetchShiftsPL/rejected':
              if (rejectedAction.payload.response?.status === 404) {
                displayError = false
              }
              break

            case 'shifts/registerToShift/rejected':
            case 'shifts/unregisterFromShift/rejected':
              errorTitle =
                rejectedAction.type == 'shifts/registerToShift/rejected'
                  ? 'shift.registrationErrorTitle'
                  : 'shift.cancellationErrorTitle'
              errorMessage = 'shift.registrationErrorDescDefault'
              switch (rejectedAction.payload.response?.status) {
                case 401:
                case 403:
                case 404:
                case 406:
                case 409:
                  errorMessage = `shift.registrationErrorDesc${rejectedAction.payload.response?.status}`
                  break
              }
              break

            case 'shifts/fetchShiftsProjectLeaders/rejected':
              if (rejectedAction.payload.response?.status === 404) {
                displayError = false
              }
              break
          }
        }
      }

      // Dispatch the setError action
      if (displayError) {
        dispatch(
          setError({
            title: i18n.t(errorTitle),
            message: i18n.t(errorMessage),
            actionType: rejectedAction.type,
          }),
        )
      }
    }

    return next(action)
  }
