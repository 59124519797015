import { ErrorMessage } from '@hookform/error-message'
import { IonLabel, IonItem, IonInput, IonButton, InputCustomEvent } from '@ionic/react'
import React, { useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '../../../hooks/reduxHooks'
import { AuthState, setSignUpData } from '../../../store/redux/slices/authSlice'
import { useNativeKeyboard } from '../../../hooks/useNativeKeyboard'
import { useSelector } from 'react-redux'
import { AppState } from '../../../store/redux/types'

type UserDetailsFormProps = {
  nextStep: () => void
}

interface UserDetailsFormInputs {
  firstname: string
  lastname: string
  email: string
  password: string
  confirmPassword: string
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({ nextStep }) => {
  const dispatch = useAppDispatch()
  const { signUpData } = useSelector((state: AppState): AuthState => state.auth)
  const { t } = useTranslation()
  const handleNativeKeyboard = useNativeKeyboard()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<UserDetailsFormInputs>({
    defaultValues: {
      firstname: signUpData?.firstname || '',
      lastname: signUpData?.lastname || '',
      email: signUpData?.email || '',
      password: signUpData?.password || '',
      confirmPassword: signUpData?.password || '',
    },
  })
  const password = useRef<string | null | undefined>('')
  password.current = watch('password', '')

  const submitHandler = async (data: UserDetailsFormInputs) => {
    const { firstname, lastname, email, password } = data
    dispatch(setSignUpData({ firstname, lastname, email, password }))
    nextStep()
  }

  return (
    <>
      <h1 className="ion-padding-vertical">{`${t('signup.personalInformation')} - ${t('signup.step1')}`}</h1>
      <form onSubmit={handleSubmit(submitHandler)}>
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="firstname"
            render={({ field: { onChange, value } }) => (
              <IonInput
                label={t('forms.firstName')}
                labelPlacement="stacked"
                onIonChange={onChange}
                onIonFocus={handleNativeKeyboard}
                autocapitalize="on"
                placeholder="Jane"
                enterkeyhint="next"
                required={true}
                inputMode="text"
                value={value}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                // eslint-disable-next-line no-misleading-character-class
                value: /^[\p{L} -j́]*$/gu,
                message: t('forms.invalidFirstName'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="firstname"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="lastname"
            render={({ field: { onChange, value } }) => (
              <IonInput
                label={t('forms.lastName')}
                labelPlacement="stacked"
                onIonChange={onChange}
                onIonFocus={handleNativeKeyboard}
                autocapitalize="on"
                placeholder="Doe"
                enterkeyhint="next"
                required={true}
                inputMode="text"
                value={value}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                // eslint-disable-next-line no-misleading-character-class
                value: /^[\p{L} -j́]*$/gu,
                message: t('forms.invalidLastName'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="lastname"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <IonInput
                type="email"
                label={t('forms.emailAddress')}
                labelPlacement="stacked"
                onIonChange={onChange}
                onIonFocus={handleNativeKeyboard}
                placeholder="example@mail.com"
                enterkeyhint="next"
                autocapitalize="off"
                inputMode="email"
                required={true}
                value={value}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                message: t('forms.invalidEmail'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="email"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <IonInput
                type="password"
                label={t('forms.password')}
                labelPlacement="stacked"
                onIonChange={(event: InputCustomEvent) => {
                  onChange(event.detail.value)
                  password.current = event.detail.value
                }}
                onIonFocus={handleNativeKeyboard}
                placeholder={t('forms.enterPassword')}
                enterkeyhint="next"
                required={true}
                inputMode="text"
                value={value}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              pattern: {
                value: /^(?=.*[a-z])(?=.*\d)[a-zA-Z\w\W]{8,}$/,
                message: t('forms.invalidPassword'),
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          name="password"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonItem className="ion-no-padding">
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { onChange, value } }) => (
              <IonInput
                type="password"
                label={t('forms.confirmPassword')}
                labelPlacement="stacked"
                onIonChange={onChange}
                onIonFocus={handleNativeKeyboard}
                placeholder={t('forms.confirmPassword')}
                enterkeyhint="send"
                required={true}
                inputMode="text"
                value={value}
              />
            )}
            rules={{
              required: `${t('forms.required')}`,
              validate: (value): boolean => {
                return value === watch('password')
              },
            }}
          />
        </IonItem>
        <ErrorMessage
          errors={errors}
          message={t('login.unmatchingPasswords')}
          name="confirmPassword"
          as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
        />
        <IonButton className="ion-margin-top" expand="block" type="submit">
          {t('btn.next')}
        </IonButton>
      </form>
    </>
  )
}

export default UserDetailsForm
