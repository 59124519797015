import { useAppDispatch } from './reduxHooks'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../store/redux/types'
import { fetchLanguagesLocal, RegionState } from '../store/redux/slices/regionSlice'
import { ConfigState } from '../store/redux/slices/configSlice'

export const useFetchLanguages = (initial: boolean = false) => {
  const dispatch = useAppDispatch()
  const { apiCode, languages } = useSelector((state: AppState): RegionState => state.region)
  const { localConfig } = useSelector((state: AppState): ConfigState => state.config)

  const hasFetchLanguages = useRef(false)
  useEffect(() => {
    if (!languages && !hasFetchLanguages.current) {
      const code = initial ? (localConfig && localConfig.ALLOW_SANDBOX ? 'sandbox' : 'belgium') : apiCode
      dispatch(fetchLanguagesLocal(code))
      hasFetchLanguages.current = true
    }
  }, [dispatch, apiCode, languages])
}
