import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  InputCustomEvent,
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRippleEffect,
  IonRow,
} from '@ionic/react'
import FooterCommon from '../../components/FooterCommon'
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks/reduxHooks'
import { AppState } from '../../store/redux/types'
import { AuthState, updatePassword, validateToken } from '../../store/redux/slices/authSlice'
import { useSelector } from 'react-redux'
import { useNativeKeyboard } from '../../hooks/useNativeKeyboard'
import { RegionApiCodes } from '../../types/types'
import ActionPage from './ActionPage'
import { Capacitor } from '@capacitor/core'
import LogoImage from '../../components/LogoImage'

interface UpdatePasswordFormParams {
  regionApiCode: RegionApiCodes
  email: string
  token: string
}

interface Payload {
  response: {
    status: number
  }
}

interface UpdatePasswordFormInputs {
  password: string
  confirmPassword: string
}

const UpdatePasswordForm: React.FC = () => {
  const dispatch = useAppDispatch()
  const { regionApiCode, email, token } = useParams<UpdatePasswordFormParams>()
  const { isLoading } = useSelector((state: AppState): AuthState => state.auth)
  const { t } = useTranslation()
  const [isValidToken, setIsValidToken] = useState<boolean | null>(null)
  const [message, setMessage] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const handleNativeKeyboard = useNativeKeyboard()

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<UpdatePasswordFormInputs>()
  const password = useRef<string | null | undefined>('')
  password.current = watch('password', '')

  const submitHandler = async (data: UpdatePasswordFormInputs) => {
    const { password } = data
    const resultAction = await dispatch(updatePassword({ regionApiCode, email, token, password }))
    if (updatePassword.fulfilled.match(resultAction)) {
      setIsSubmitted(true)
      setShowAlert(true)
    }
  }

  const hasTriedToValidateToken = useRef(false)
  useEffect(() => {
    if (!hasTriedToValidateToken.current) {
      hasTriedToValidateToken.current = true
      dispatch(validateToken({ regionApiCode, email, token })).then((result) => {
        setIsValidToken(result.meta.requestStatus === 'fulfilled')
        if (result.meta.requestStatus !== 'fulfilled') {
          const payload: Payload = result.payload as Payload
          switch (payload.response.status) {
            case 400:
              setMessage(t('updatePassword.expiredToken'))
              break
            case 404:
              setMessage(t('updatePassword.invalidToken'))
              break
            default:
              setMessage(t('updatePassword.errorToken'))
              break
          }
        }
      })
    }
  }, [dispatch, email, token, t, regionApiCode])

  if (!isValidToken) {
    return <ActionPage title={t('updatePassword.title')} isLoading={isLoading} message={message} />
  }

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol className="text-center">
              <h2>&nbsp;</h2>
              {Capacitor.getPlatform() === 'ios' && <div>&nbsp;</div>}
              <LogoImage />
              <h1 className="ion-padding-vertical">{t('updatePassword.title')}</h1>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" size-md="6" size-lg="4" size-xl="3">
              <form onSubmit={handleSubmit(submitHandler)}>
                <IonItem className="ion-no-padding">
                  <Controller
                    control={control}
                    name="password"
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <IonInput
                        type="password"
                        label={t('forms.password')}
                        labelPlacement="stacked"
                        onIonChange={(event: InputCustomEvent) => {
                          onChange(event.detail.value)
                          password.current = event.detail.value
                        }}
                        onIonFocus={handleNativeKeyboard}
                        placeholder={t('forms.enterPassword')}
                        enterkeyhint="next"
                        required={true}
                        inputMode="text"
                      />
                    )}
                    rules={{
                      required: `${t('forms.required')}`,
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*\d)[a-zA-Z\w\W]{8,}$/,
                        message: t('forms.invalidPassword'),
                      },
                    }}
                  />
                </IonItem>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
                />
                <IonItem className="ion-no-padding">
                  <Controller
                    control={control}
                    name="confirmPassword"
                    defaultValue=""
                    render={({ field: { onChange } }) => (
                      <IonInput
                        type="password"
                        label={t('forms.confirmPassword')}
                        labelPlacement="stacked"
                        onIonChange={onChange}
                        onIonFocus={handleNativeKeyboard}
                        placeholder={t('forms.confirmPassword')}
                        enterkeyhint="send"
                        required={true}
                        inputMode="text"
                      />
                    )}
                    rules={{
                      required: `${t('forms.required')}`,
                      validate: (value): boolean => {
                        return value === watch('password')
                      },
                    }}
                  />
                </IonItem>
                <ErrorMessage
                  errors={errors}
                  message={t('login.unmatchingPasswords')}
                  name="confirmPassword"
                  as={<IonLabel className="validationErrorMsg ion-text-wrap" color="danger" />}
                />
                {isSubmitted ? (
                  <IonButton className="ion-margin-top" color="success" disabled expand="block" type="submit">
                    {t('updatePassword.success')}
                  </IonButton>
                ) : (
                  <IonButton
                    disabled={isLoading}
                    className="ion-margin-top ion-activatable ripple-parent"
                    expand="block"
                    type="submit"
                  >
                    {t('btn.updatePassword')}
                    <IonRippleEffect />
                  </IonButton>
                )}
              </form>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="text-center ion-margin-top ion-padding">
              <p>or...</p>
              <IonButton fill="outline" href="/dashboard" className="">
                {t('btn.goToDashboard')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <FooterCommon></FooterCommon>
      </IonContent>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false)
        }}
        header={t('updatePassword.successTitle')}
        message={t('updatePassword.success')}
        buttons={['OK']}
      />
    </IonPage>
  )
}

export default UpdatePasswordForm
